import React from "react"
import { Box, Button, Link, Text } from "@chakra-ui/core"
import styled from "styled-components"

export default () => {
  return (
    <Box bg="#293FEE">
      <Box
        maxW={"screen-xl"}
        mx="auto"
        py="3"
        px="6"
        color="white"
        // class="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="between"
          flexWrap="wrap"
        >
          <Box
            flex="1"
            display="flex"
            alignItems="center"
            // class="w-0 flex-1 flex items-center"
          >
            <Text
              as="span"
              display="flex"
              p="2"
              borderRadius="10px"
              bg="#293FEE"
            >
              <svg
                style={{ color: "white", height: "40px", width: "60px" }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                />
              </svg>
            </Text>
            <Text ml="3" class="ml-3 font-medium text-white truncate">
              <Text as="span" d="none">
                We announced a new product!
              </Text>
              <Text as="span">
                Big news! We're excited to announce a brand new product.
              </Text>
            </Text>
          </Box>
          <Box
            w="fit-content"
            order="2"
            flexShrink="0"
            class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto"
          >
            <Box w="fit-content" class="rounded-md shadow-sm">
              <Link
                display="flex"
                alignItems="center"
                justifyContent="center"
                px="4"
                py="2"
                fontFamily="Sofia Pro"
                border="transparent"
                fontSize="1rem"
                bg="white"
                color="#293FEE"
                href="#"
                borderRadius="5px"
                w="fit-content"
                mr="10px"
                // class="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
              >
                Learn more
              </Link>
            </Box>
          </Box>
          <Box order="3" class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <CloseButton
              type="button"
              display="flex"
              p="2px"
              mr="1"
              bg="transparent"
              borderRadius="md"
              transition="all 150ms ease-in-out"
              class="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500 sm:-mr-2 transition ease-in-out duration-150"
              aria-label="Dismiss"
            >
              <svg
                style={{ color: "white", height: "20px", width: "20px" }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </CloseButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const CloseButton = styled(Button)`
  &:hover svg {
    stroke: #293fee;
  }
`
