import React from "react"
import { Link as Glink } from "gatsby"
import { Box, Text, Link } from "@chakra-ui/core"
import Banner from "./Banner"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <Text as={"h1"} fontSize="4xl" fontWeight="bold" color="white" to="/">
        {title}
      </Text>
    )
  } else {
    header = (
      <Text as={"h1"} color="white" className="header-link-home" to="/">
        {title}
      </Text>
    )
  }

  return (
    <Box className="global-wrapper" data-is-root-path={isRootPath}>
      <Box
        className="global-header"
        borderBottom={"1px"}
        pb={10}
        color="#9b9b9b8f"
      >
        <Text color="white">{header}</Text>
      </Box>

      <main>{children}</main>
      <Text color="white">
        © {new Date().getFullYear()} Made with ❤️ by{" "}
        <Link href="https://ahmedmire.com">Ahmed Mire</Link>
      </Text>
    </Box>
  )
}

export default Layout
